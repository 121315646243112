/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import {
  connectStateResults,
  connectCurrentRefinements,
} from "react-instantsearch-dom"

import CloseIcon from "src/assets/svg/close.inline.svg"

export const SearchFiltersTitle = ({ children }) => (
  <h2 sx={{ fontWeight: "bold", width: "100%" }}>{children}</h2>
)
export const SearchFilters = connectStateResults(
  ({ searchResults, children }) => {
    if (!searchResults || (searchResults && searchResults.nbHits === 0)) {
      return null
    }
    return (
      <div
        sx={{
          marginTop: [20, null, null, null],
          marginBottom: [60, null, null, 96],
          maxWidth: 795,
          "& > *": {
            marginBottom: 20,
          },
        }}
      >
        {children}
      </div>
    )
  }
)

export const SearchFiltersContainer = ({ children }) => {
  return (
    <div>
      <Flex
        sx={{
          flexWrap: "wrap",
          margin: "unset",
          "& > *": {
            width: ["100%", null, null, 200],
            margin: [
              "0 0 10px 0 !important",
              null,
              null,
              "0 10px 0 0 !important",
            ],
          },
          filter: "drop-shadow(0px 4px 16px hsl(32deg 65.2% 18% / 16%))",
        }}
      >
        {children}
      </Flex>
    </div>
  )
}

const ClearRefinements = ({ items, refine, onClearFilters }) => {
  const onClick = () => {
    if (onClearFilters) onClearFilters()
  }

  return (
    <button
      onClick={onClick}
      disabled={!items.length}
      sx={{
        variant: "search.underlineLink",
        fontWeight: "normal",
        color: "black",
        "& > svg": {
          width: "10px",
          height: "10px",
        },
        "&:disabled": { color: "#585858" },
      }}
    >
      <CloseIcon aria-hidden="true" />
      <span sx={{ ml: "14px" }}>Clear all filters</span>
    </button>
  )
}

export const SearchFiltersClear = connectCurrentRefinements(ClearRefinements)
