/** @jsx jsx */
import { jsx } from "theme-ui"
import { connectHighlight } from "react-instantsearch-dom"

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  })

  return (
    <span
      sx={{
        display: "block",
        padding: "10px 20px",
        fontSize: "16px",
      }}
    >
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <mark
            sx={{
              fontWeight: "bold",
              background: "none",
            }}
            key={index}
          >
            {part.value}
          </mark>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  )
}

export default connectHighlight(Highlight)
