/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx, Box } from "theme-ui"
import ReactSelect from "react-select"
import useViewport from "src/hooks/useViewport"

const ArrowDownIcon = () => (
  <svg
    sx={{ fill: "currentColor" }}
    height="20"
    width="20"
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
  >
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
  </svg>
)

const Select = ({ id, options, placeholder, onChange, value, ...props }) => {
  const { width } = useViewport()
  return (
    <>
      <MobileSelect
        id={width < 600 ? id : undefined}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <DesktopSelect
        id={width >= 600 ? id : undefined}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        {...props}
      />
    </>
  )
}

export default Select

const MobileSelect = ({ id, options, placeholder, onChange, value }) => (
  <Box
    sx={{
      width: "100%",
      padding: "5px 4px",
      border: "none",
      borderRadius: "4px",
      minHeight: "48px",
      position: "relative",
      margin: "inherit",

      display: "block",
      "& + div": {
        display: "none",
      },
      "@media (min-width: 600px)": {
        display: "none",
        "& + div": {
          display: "block",
        },
      },
    }}
  >
    <Box
      as="select"
      id={id}
      value={value?.value || ""}
      onChange={(event) => onChange(event.target)}
      sx={{
        margin: "inherit",
        fontFamily: "body",
        borderRadius: "4px",
        padding: "14px",
        fontSize: "16px",
        border: 0,
        lineHeight: 1,
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        appearance: "none",
        webkitAppearance: "none",
        mozAppearance: "none",
        pointerEvents: "auto",
        color: "#585858",
        "&:hover": { color: "black" },
        "&:focus": {
          color: "black",
          outline: "2px solid #E6A80D",
        },
        "&:hover + div": { color: "black" },
        "&:focus + div": { color: "black" },
      }}
    >
      <>
        <option value="">{placeholder}</option>
        {options.map(({ value, label }) => (
          <option key={label} value={value}>
            {label}
          </option>
        ))}
      </>
    </Box>
    <Box
      sx={{
        position: "absolute",
        height: "20px",
        width: "20px",
        right: "12px",
        top: "14px",
        zIndex: 2,
        pointerEvents: "none",
        color: "#585858",
      }}
    >
      <ArrowDownIcon />
    </Box>
  </Box>
)

const DesktopSelect = ({
  id,
  options,
  placeholder,
  onChange,
  value,
  ...props
}) => {
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "100%",
      padding: "5px 4px",
      border: "none",
      borderRadius: "4px",
      outline: state.isFocused ? "2px solid #E6A80D" : "none",
      backgroundColor: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#585858",
      lineHeight: 1,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: 24,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor:
        state.isSelected || state.isFocused ? "#58585833" : "white",
      color: "black",
      "&:hover": {
        backgroundColor: "#58585833",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      color: state.isFocused ? "black" : "#585858",
      "&:hover div div": { color: "black" },
    }),
    indicatorSeparator: () => null,
    menu: (provided) => ({
      ...provided,
      marginLeft: -4,
      boxShadow: "none",
    }),
  }

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "black",
      primary25: "#EBEBEB",
      neutral20: "#585858",
      neutral40: "black",
    },
  })

  return (
    <div>
      <ReactSelect
        inputId={id}
        options={options}
        onChange={onChange}
        theme={customTheme}
        isSearchable={false}
        isClearable={false}
        placeholder={placeholder}
        styles={customStyles}
        menuShouldScrollIntoView
        closeMenuOnScroll
        defaultValue={value}
        value={value}
        {...props}
      />
    </div>
  )
}
