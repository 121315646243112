/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { connectMenu } from "react-instantsearch-dom"

import Select from "src/components/Select"
import VisuallyHidden from "src/components/VisuallyHidden"

const SearchSelectFilter = ({
  items,
  currentRefinement,
  defaultText,
  onSelectFilter,
  attribute,
}) => {
  // If there isn't any items don't return anything
  if (!items || items.length <= 0) return null

  const current = items.find((item) => currentRefinement === item.label)

  const placeholder = `Select ${defaultText.split(" ").slice(2).join(" ")}`

  const currentFormatted = current
    ? {
        label: `${current.label} (${current.count})`,
        value: current.isRefined ? currentRefinement : current.value,
      }
    : { label: placeholder, value: "" }

  const newItems = items
    .map((item) => ({
      label: `${item.label} (${item.count})`,
      value: item.isRefined ? currentRefinement : item.value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const onChange = (target) => {
    if (onSelectFilter) {
      onSelectFilter(attribute, target.value)
    }
  }

  let label = defaultText.split(" ").slice(2).join(" ")
  label = label[0].toUpperCase() + label.substring(1)

  return (
    <Flex sx={{ flexDirection: "column", gap: 2 }}>
      <label htmlFor={`filter-input-${defaultText.split(" ")[2]}`}>
        <VisuallyHidden>Filter by</VisuallyHidden> {label}
      </label>
      <Select
        id={`filter-input-${defaultText.split(" ")[2]}`}
        options={
          defaultText === "Filter by years" ? newItems.reverse() : newItems
        }
        placeholder={placeholder}
        value={currentFormatted}
        onChange={onChange}
      />
    </Flex>
  )
}

export default connectMenu(SearchSelectFilter)
