/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import he from "he"
import slugify from "slugify"
import Tooltip from "@reach/tooltip"
import "@reach/tooltip/styles.css"

import { childrenElementProp, hitProp } from "./SearchHitProps"
import DownloadIcon from "./icons/Download"
import ArrowIcon from "./icons/Arrow"
import { focusOutline } from "src/theme"

const DOWNLOAD_LIST_CUTOFF = 2

const getDownloadUrl = (sourceLocal, sourceRemote) => {
  return sourceLocal?.publicUrl ? sourceLocal.publicUrl : sourceRemote
}
const getSlashLink = (link = "") => (link.startsWith("/") ? link : `/${link}`)

export const SearchItem = ({ children }) => (
  <Flex
    data-testid="search-search-item"
    sx={{
      marginBottom: "50px",
      flexDirection: "column",
      width: "100%",
      padding: [3, false, 6, false, false, false],
      maxWidth: "900px",
      boxShadow: "0px 4px 16px 0px #4C301029",
      borderRadius: "8px",
    }}
  >
    {children}
  </Flex>
)

SearchItem.propTypes = {
  children: childrenElementProp,
}

export const SearchItemHeader = ({ children }) => (
  <Flex
    sx={{
      justifyContent: "space-between",
      alignItems: "center",
      mb: 3,
      color: "grey",
      fontSize: 1,
    }}
  >
    {children}
  </Flex>
)

SearchItemHeader.propTypes = {
  children: childrenElementProp,
}

export const SearchItemMain = ({ children }) => (
  <Flex sx={{ flex: "1", flexDirection: "column" }}>{children}</Flex>
)

SearchItemMain.propTypes = {
  children: childrenElementProp,
}

export const SearchItemFooter = ({ children }) => (
  <Flex
    sx={{
      justifyContent: "space-between",
      alignItems: ["flex-start", false, "center", false, false, false],
      flexDirection: ["column", false, "row", false, false, false],
    }}
  >
    {children}
  </Flex>
)

SearchItemFooter.propTypes = {
  children: childrenElementProp,
}

export const SearchItemTitle = ({ link, children }) => {
  return (
    <h3
      sx={{
        mb: 16,
      }}
    >
      <Link
        sx={{
          fontSize: [3, false, 5, false, false, false],
          fontWeight: "bold",
          lineHeight: "1.1",
          color: "black",
          "&:is(:hover, :focus-visible)": {
            textDecoration: "none",
          },
          "&:focus-visible": {
            outline: "2px solid #EDB936",
          },
        }}
        to={getSlashLink(link)}
      >
        {typeof children === "string" ? he.decode(children) : children}
      </Link>
    </h3>
  )
}

SearchItemTitle.propTypes = {
  children: PropTypes.string.isRequired,
}

export const SearchItemDescription = ({ children }) => (
  <p sx={{ lineHeight: "1.6", mb: 3 }}>{he.decode(children)}</p>
)

SearchItemDescription.propTypes = {
  children: PropTypes.string.isRequired,
}

export const SearchButton = ({ children, theme = "yellow", ...props }) => {
  const themes = {
    yellow: {
      backgroundColor: "#EDB936",
      color: "#000",
    },
  }

  const buttonTheme = themes[theme]

  return (
    <button
      sx={{
        variant: "button.default",
        fontSize: 2,
        ...buttonTheme,
        maxWidth: "max-content",
        py: 3,
        px: 3, // Padding short hand wasn't working
        alignItems: "center",
      }}
      {...props}
    >
      {children}
    </button>
  )
}

export const Download = ({
  id,
  download,
  downloadIndex,
  isOpen,
  _sx,
  firstResultAfterShowMoreRef,
}) => {
  const {
    title,
    fileTypeFormatted,
    fileSizeFormatted,
    sourceRemote,
    sourceLocal,
  } = download

  return (
    <Flex
      as="a"
      sx={{
        display: () =>
          isOpen || downloadIndex < DOWNLOAD_LIST_CUTOFF ? "flex" : "none",
        alignItems: "flex-start",
        mb: 3,
        "&:last-child": { mb: 0 },
        textDecoration: "none",
        color: "black",
        gap: "8px",

        ...focusOutline,
        ..._sx,
      }}
      id={id}
      href={getDownloadUrl(sourceLocal, sourceRemote)}
      ref={firstResultAfterShowMoreRef}
    >
      <span aria-hidden="true" sx={{ flexShrink: 0, display: "flex" }}>
        <DownloadIcon />
      </span>
      <span>
        <span
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {he.decode(title)}
        </span>
        <span
          sx={{
            whiteSpace: "nowrap",
            ml: "4px",
          }}
        >
          ({fileTypeFormatted}, {fileSizeFormatted})
        </span>
      </span>
    </Flex>
  )
}

export const DownloadsSection = ({
  title,
  sectionIndex,
  isOpen,
  firstResultAfterShowMoreRef,
  children,
}) => {
  return (
    <div
      sx={{
        display: () => (isOpen || sectionIndex === 0 ? "block" : "none"),
      }}
    >
      <h4
        ref={firstResultAfterShowMoreRef}
        tabIndex={firstResultAfterShowMoreRef ? "-1" : undefined}
        sx={{
          mb: 5,
          "&:focus-visible": {
            outlineColor: "yellow",
            outlineOffset: "2px",
          },
        }}
      >
        {title}
      </h4>
      {children}
    </div>
  )
}

export const SearchItemDownloadList = ({ hit, ...props }) => {
  const firstResultAfterShowMoreRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const ariaControls = []

  useEffect(() => {
    if (isOpen) {
      firstResultAfterShowMoreRef.current?.focus()
      console.log(firstResultAfterShowMoreRef)
    }
  }, [isOpen])

  const downloadsSections = hit?.richContent
    ? hit.richContent.filter(({ downloads }) => downloads)
    : null

  return (
    <Flex
      sx={{
        flexDirection: "column",
        padding: 3,
        backgroundColor: "rgba(235, 235, 235, 1)",
        borderRadius: "4px",
        gap: 5,
      }}
      {...props}
    >
      {downloadsSections.map(({ title, downloads }, sectionIndex) => {
        return (
          <DownloadsSection
            key={`${title}${sectionIndex}`}
            title={title}
            sectionIndex={sectionIndex}
            isOpen={isOpen}
            firstResultAfterShowMoreRef={
              sectionIndex === 1 &&
              downloadsSections[0].downloads.length <= DOWNLOAD_LIST_CUTOFF
                ? firstResultAfterShowMoreRef
                : undefined
            }
          >
            {downloads.map(({ download }, downloadIndex) => {
              const id = `download-${downloadIndex + 1}-${slugify(hit.title)}`
              ariaControls.push(id)
              return (
                <Download
                  key={`${title}${downloadIndex}`}
                  id={id}
                  download={download}
                  downloadIndex={downloadIndex}
                  isOpen={isOpen}
                  _sx={
                    !isOpen &&
                    sectionIndex === 0 &&
                    downloadIndex === DOWNLOAD_LIST_CUTOFF - 1
                      ? { mb: 0 }
                      : {}
                  }
                  firstResultAfterShowMoreRef={
                    sectionIndex === 0 && downloadIndex === DOWNLOAD_LIST_CUTOFF
                      ? firstResultAfterShowMoreRef
                      : undefined
                  }
                />
              )
            })}
          </DownloadsSection>
        )
      })}
      {(downloadsSections.length > 1 ||
        downloadsSections[0].downloads.length > DOWNLOAD_LIST_CUTOFF) && (
        <SearchButton
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls={ariaControls.join(" ")}
        >
          <span
            sx={{
              textDecoration: "underline",
              mr: 2,
            }}
          >
            {isOpen ? "Show less" : "Show more"}
          </span>
          <span sx={{ flexShrink: 0 }} aria-hidden="true">
            <ArrowIcon isOpen={isOpen} />
          </span>
        </SearchButton>
      )}
    </Flex>
  )
}

SearchItemDownloadList.propTypes = {
  hit: hitProp,
}

export const SearchCategroyTaxonomies = ({ taxonomies }) => {
  if (!taxonomies || taxonomies.length === 0) {
    return null
  }

  const categories = [...taxonomies[0].category]
  const limitValue = 2
  let overLimitCategories
  if (categories.length > limitValue) {
    overLimitCategories = categories
      .slice(limitValue)
      .reduce(
        (prevCategory, category, index, categories) =>
          prevCategory +
          (categories.length > limitValue &&
          index === Math.floor(categories.length / 2)
            ? ", "
            : index < categories.length - 1
            ? ", "
            : ", and ") +
          category
      )
  }
  return (
    <div sx={{ mb: [6, null, 0, null, null, null] }}>
      {categories.slice(0, limitValue + 1).map((category, index) =>
        index > limitValue ? null : index === limitValue ? (
          <Tooltip
            key={`${category}${index}`}
            label={overLimitCategories}
            style={{
              backgroundColor: "#EBEBEB",
              borderRadius: "8px",
              border: "none",
              px: "6px",
              maxInlineSize: "30ch",
              whiteSpace: "revert",
            }}
          >
            <button
              sx={{
                variant: "button.void",
                backgroundColor: "#EBEBEB",
                borderRadius: "8px",
                px: "6px",
              }}
            >
              {categories.length - limitValue}+
            </button>
          </Tooltip>
        ) : (
          <span
            key={`${category}${index}`}
            sx={{
              backgroundColor: "#EBEBEB",
              borderRadius: "8px",
              p: "6px",
              mr: "12px",
            }}
          >
            {category}
          </span>
        )
      )}
    </div>
  )
}

SearchCategroyTaxonomies.propTypes = {
  taxonomise: PropTypes.shape([
    { category: PropTypes.arrayOf(PropTypes.string) },
  ]),
}

const formatDateDD_MONTH_YYYY = (date) => {
  return new Date(date).toLocaleDateString("en-AU", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

export const SearchFormattedDate = ({ date, ...rest }) => (
  <time dateTime={date} sx={{ color: "grey" }} {...rest}>
    {date ? formatDateDD_MONTH_YYYY(date) : ""}
  </time>
)

SearchFormattedDate.propTypes = {
  date: PropTypes.string,
}

export const SearchLink = ({ to, children }) => (
  <Link
    to={getSlashLink(to)}
    sx={{
      variant: "links.h4",
      fontSize: [2, false, 3, false, false, false],
      color: "black",
      mb: 0,
      "&:is(:hover, :focus-visible)": {
        textDecoration: "none",
      },
      "&:focus-visible": {
        outline: "2px solid #EDB936",
      },
    }}
    aria-hidden="true" // This is a redundant link, so we're removing it for screen reader users https://www.w3.org/TR/WCAG21/#link-purpose-in-context
    tabIndex="-1"
  >
    {children}
  </Link>
)

SearchLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export const SearchDot = ({ children }) => (
  <p
    sx={{
      display: "inline-block",
      fontWeight: "600",
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: "26px",
      color: "black",
      backgroundColor: "#EDB936",
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      mr: 1,
      ml: 2,
    }}
  >
    {children}
  </p>
)

SearchDot.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.number]),
}
