/** @jsx jsx */
import { useEffect, useState, useRef } from "react"
import { jsx, Box } from "theme-ui"
import theme from "src/theme"
import { connectAutoComplete } from "react-instantsearch-dom"
import AutoSuggest from "react-autosuggest"

import Highlight from "./Highlight"
import { CloseButton, SearchButton } from "./SearchButtons"

const SearchAutocomplte = ({
  hits: suggestions,
  currentRefinement: autoSuggestQuery,
  refine: setAutoSuggestQuery,
  setSearchQuery,
  smoothScrollToFilters,
  setAutoSuggestQueryRef,
}) => {
  const searchAutoCompleteFormRef = useRef(null)
  setAutoSuggestQueryRef.current = setAutoSuggestQuery
  const [newSuggestions, setNewSuggestions] = useState(suggestions)

  useEffect(() => {
    document
      .getElementById("react-autowhatever-1")
      .setAttribute("aria-label", "search suggestion list")
  }, [])

  // Every time the suggestions or autoSuggestQuery are updated:
  useEffect(() => {
    // If the user hasn't entered a query show no suggestions
    if (!autoSuggestQuery) {
      setNewSuggestions([])
    }
    // If the user has entered a query longer than 3 characters show the suggestions
    if (autoSuggestQuery?.length >= 3) {
      setNewSuggestions(suggestions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSuggestQuery])

  const submitSearchQuery = (event, autoSuggestProps) => {
    // submitSearchQuery is called from:
    //  - The searchAutoCompleteForm's onSubmit
    //  - The SearchButton's onClick
    //  - The AutoSuggest's onSuggestionSelected
    event.preventDefault()

    // The suggestion value only exists when submitSearchQuery is called from the AutoSuggest's onSuggestionSelected
    const query =
      autoSuggestProps?.suggestionValue ??
      searchAutoCompleteFormRef.current.elements.search_autosuggest.value

    // Stop if there's no query
    if (!query) return

    // Set the SearchQuery to either typed input or selected suggestion
    setSearchQuery(query)

    smoothScrollToFilters()
  }

  const onChange = (event, { newValue }) => {
    event.preventDefault()

    // Set the AutoSuggest query to newValue
    setAutoSuggestQuery(newValue)
  }

  const onReset = (event) => {
    event.preventDefault()

    // Clear the AutoSuggest query
    setAutoSuggestQuery("")
  }

  // Following functions are AutoSuggest props, see docs:
  // https://github.com/moroshko/react-autosuggest#props

  const onSuggestionsFetchRequested = ({ value }) => setAutoSuggestQuery(value)

  const onSuggestionsClearRequested = () => {}

  const getSuggestionValue = ({ query }) => query

  const renderSuggestion = (suggestion) => (
    <Highlight attribute="query" hit={suggestion} tagName="mark" />
  )

  const inputProps = {
    id: "search-input",
    "data-testid": "search-autosuggest-input",
    placeholder: "Enter a search term",
    onChange,
    value: autoSuggestQuery,
    name: "search_autosuggest",
  }

  const autoSuggestTheme = {
    // Had to use classes here as these theme styles are inline styles so they can't have pseudo classes
    container: "react-autosuggest__container",
    containerOpen: "react-autosuggest__container--open",
    input: {
      outline: "none",
      border: "none",
      backgroundColor: "transparent",
      inlineSize: "100%",
      padding: "16px 50px",
      fontSize: "1rem",
    },
    inputOpen: {
      boxShadow: `${
        newSuggestions.length < 10
          ? "none"
          : "0px 4px 16px hsl(32deg 65% 18% / 16%)"
      }`,
    },
    suggestionsContainerOpen: {
      maxHeight: "420px",
      overflowY: "auto",
    },
    suggestion: {
      cursor: "pointer",
    },
    suggestionHighlighted: {
      backgroundColor: "hsl(0deg 0% 92%)",
    },
  }

  return (
    <Box
      sx={{
        blockSize: 50,
        position: "relative",
      }}
    >
      <Box
        ref={searchAutoCompleteFormRef}
        as="form"
        role="search"
        onSubmit={submitSearchQuery}
        sx={{
          isolation: "isolate",
          position: "absolute",
          zIndex: 1,
          inlineSize: "100%",
          maxInlineSize: 600,
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          blockSize: "fit-content",
          backgroundColor: "white",
          borderRadius: "5px",
          outline: "2px solid black",

          "&:focus-within": {
            outline: `2px solid ${theme.colors.yellows[0]}`,
            boxShadow: "0px 4px 16px hsl(32deg 65% 18% / 16%)",
          },

          "&:hover": {
            outline: `2px solid ${theme.colors.yellows[0]}`,
          },

          ".react-autosuggest__container": {
            gridColumn: "1 / 4",
            gridRow: "1 / 2",
            border: "none",
            borderRadius: "5px",
          },
          ".react-autosuggest__container--open": {
            outline: `2px solid ${theme.colors.yellows[0]}`,
            boxShadow: "0px 4px 16px hsl(32deg 65% 18% / 16%)",
          },
        }}
      >
        <SearchButton
          data-testid="search-search-button"
          onClick={submitSearchQuery}
          sx={{ zIndex: 2, gridColumn: "1 / 2", gridRow: "1 / 2" }}
        />
        <AutoSuggest
          data-testid="search-autosuggest"
          suggestions={newSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={submitSearchQuery}
          focusInputOnSuggestionClick={false}
          theme={autoSuggestTheme}
        />
        {autoSuggestQuery && (
          <CloseButton
            data-testid="search-close-button"
            onClick={onReset}
            sx={{ zIndex: 2, gridColumn: "3 / 4", gridRow: "1 / 2" }}
          />
        )}
      </Box>
    </Box>
  )
}

export default connectAutoComplete(SearchAutocomplte)
