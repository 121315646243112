/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Fragment, useRef, useState, useEffect } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import algoliasearch from "algoliasearch"
import { InstantSearch, connectSearchBox } from "react-instantsearch-dom"
import qs from "qs"
import aa from "search-insights"

import SEO from "src/components/seo"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import Header from "src/components/Header"
import HeroStandard from "src/components/HeroStandard"
import Section from "src/components/Section"
import Container from "src/components/Container"
import { EnquiryForm } from "src/components/Forms"
import Footer from "src/components/Footer"
import SearchHits from "src/components/SearchHits"
import SearchPagination from "src/components/SearchPagination"
import SearchAutocomplete from "src/components/SearchAutocomplete"
import LinkListHubs from "src/slices/LinkListHubs"
import {
  SearchFilters,
  SearchFiltersContainer,
  SearchFiltersTitle,
  SearchSelectFilter,
  SearchFiltersClear,
} from "src/components/SearchFilters"
import {
  SearchStats,
  SearchStatsHits,
  SearchStatsHitsPerPage,
} from "src/components/SearchStats"
import usePrefersReducedMotion from "src/hooks/usePrefersReducedMotion"

aa("init", {
  appId: process.env.GATSBY_ALGOLIA_APPID,
  apiKey: process.env.GATSBY_ALGOLIA_APIKEY,
})

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APPID,
  process.env.GATSBY_ALGOLIA_APIKEY
)

const searchClient = {
  ...algoliaClient,
  search(requests) {
    // Conditional Requests: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/

    // Detecting empty search requests
    if (requests.every(({ params }) => !params.query)) {
      // "When an empty search is detected, you must return a formatted response as an array of objects of the same length as the requests array."
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

// Create a URL query string from state
const createURL = (state) =>
  qs.stringify(state, { addQueryPrefix: true, format: "RFC1738" })

// Transform searchState into a URL query string
const searchStateToUrlQueryString = (searchState) =>
  searchState ? createURL(searchState) : ""

//Transform a URL query string into searchState
const urlQueryStringToSearchState = (urlQueryString) =>
  urlQueryString === ""
    ? {
        query: undefined,
        page: 1,
        postType: undefined,
        "taxonomies.category": undefined,
        "taxonomies.years": undefined,
      }
    : qs.parse(urlQueryString.slice(1))

const Search = ({ location }) => {
  const { wpgraphql: data } =
    useStaticQuery(graphql`
      query SearchOptionsQuery {
        wpgraphql {
          acfSearchOrder {
            isSearchPostTypePriority
            searchPostTypePriorityOrder
            searchPriorityOrderThreshold
          }
        }
      }
    `) || {}

  const urlQueryString = location.search
  const prefersReducedMotion = usePrefersReducedMotion()
  const filtersRef = useRef(null)
  const setAutoSuggestQueryRef = useRef(null)
  const [searchState, setSearchState] = useState(
    urlQueryStringToSearchState(urlQueryString)
  )
  const setSearchQuery = (query) => {
    onSearchStateChange({
      ...searchState,
      query,
      page: 1,
      postType: undefined,
      "taxonomies.category": undefined,
      "taxonomies.years": undefined,
    })
  }
  const VirtualSearchBox = connectSearchBox(() => null)

  const onSearchStateChange = (updatedSearchState) => {
    // If the query exists and isn't the same as the current query, update the searchState
    if (
      updatedSearchState.query &&
      searchStateToUrlQueryString(updatedSearchState) !==
        searchStateToUrlQueryString(searchState)
    ) {
      navigate(searchStateToUrlQueryString(updatedSearchState))
    }
  }

  // Synchronise search state stored in the URL with the local state
  useEffect(() => {
    const updatedSearchState = urlQueryStringToSearchState(urlQueryString)

    setSearchState(updatedSearchState)

    if (
      updatedSearchState.query === undefined &&
      setAutoSuggestQueryRef.current !== null
    ) {
      setAutoSuggestQueryRef.current("")
    }
  }, [urlQueryString])

  const onSelectFilter = (attribute, value) => {
    onSearchStateChange({
      ...searchState,
      [attribute]: value,
    })
  }

  const onClearFilters = () => {
    onSearchStateChange({
      ...searchState,
      postType: undefined,
      "taxonomies.category": undefined,
      "taxonomies.years": undefined,
    })
  }

  const smoothScrollToFilters = () => {
    // Checks if the user prefers reduced motion and if so, skips the smooth scroll.
    let scrollBehavior = prefersReducedMotion ? "auto" : "smooth"

    if (filtersRef.current !== null) {
      // Timeout needed as sometimes scrollIntoView doesn't work due to dom element not existing yet
      setTimeout(() => {
        filtersRef.current.scrollIntoView({ behavior: scrollBehavior })
        filtersRef.current.focus()
      }, 500)
    }
  }

  return (
    <Fragment>
      <SEO title="Search" />

      <SkipNavLink
        sx={{
          variant: "link.skipNav",
        }}
      />

      <Header path={location.pathname} />

      <Box as="main" sx={{ isolation: "isolate" }}>
        <SkipNavContent />
        <HeroStandard
          title="<label for='search-input'>Search</label>"
          breadcrumbs={[{ title: "Home", link: "/" }]}
        >
          <InstantSearch
            searchClient={searchClient}
            indexName={`${process.env.GATSBY_ALGOLIA_INDEXNAME}_query_suggestions`}
          >
            <SearchAutocomplete
              searchQuery={searchState.query}
              setSearchState={setSearchState}
              setSearchQuery={setSearchQuery}
              smoothScrollToFilters={smoothScrollToFilters}
              setAutoSuggestQueryRef={setAutoSuggestQueryRef}
            />
          </InstantSearch>
        </HeroStandard>

        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.GATSBY_ALGOLIA_INDEXNAME}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
        >
          <VirtualSearchBox defaultRefinement={searchState.query} />

          <Section
            as="section"
            sx={{
              variant: "section.medium",
            }}
          >
            <Container>
              <div
                ref={filtersRef}
                tabIndex="-1"
                sx={{
                  "&:focus-visible": {
                    outlineColor: "yellow",
                    outlineOffset: "10px",
                  },
                }}
              >
                <SearchFilters>
                  <SearchFiltersTitle>Filter by:</SearchFiltersTitle>
                  <SearchFiltersContainer>
                    <SearchSelectFilter
                      attribute="postType"
                      defaultText="Filter by post type"
                      limit={50}
                      onSelectFilter={onSelectFilter}
                      defaultValue={searchState.postType}
                      defaultRefinement={searchState.postType}
                    />
                    <SearchSelectFilter
                      attribute="taxonomies.category"
                      defaultText="Filter by category"
                      limit={50}
                      onSelectFilter={onSelectFilter}
                      defaultValue={searchState["taxonomies.category"]}
                      defaultRefinement={searchState["taxonomies.category"]}
                    />
                    <SearchSelectFilter
                      attribute="taxonomies.years"
                      defaultText="Filter by years"
                      limit={50}
                      onSelectFilter={onSelectFilter}
                      defaultValue={searchState["taxonomies.years"]}
                      defaultRefinement={searchState["taxonomies.years"]}
                    />
                  </SearchFiltersContainer>
                  <SearchFiltersClear onClearFilters={onClearFilters} />
                </SearchFilters>
              </div>

              <SearchStats>
                <div>
                  <SearchStatsHits query={searchState.query} />
                </div>
                <SearchStatsHitsPerPage
                  query={searchState.query}
                  searchState={searchState}
                  onSearchStateChange={onSearchStateChange}
                />
              </SearchStats>

              <SearchHits
                query={searchState.query}
                searchOrder={data?.acfSearchOrder}
              />

              <SearchPagination
                searchState={searchState}
                onSearchStateChange={onSearchStateChange}
                smoothScrollToFilters={smoothScrollToFilters}
              />
            </Container>
          </Section>
        </InstantSearch>
        <LinkListHubs
          sectionHeading={"Here are some helpful starting points"}
          active={true}
        />
        <Box mt={20}>
          <EnquiryForm location={location} />
        </Box>
      </Box>
      <Footer />
    </Fragment>
  )
}

export default Search
