/** @jsx jsx */
import { jsx, Flex, Select } from "theme-ui"
import { connectStats, connectHitsPerPage } from "react-instantsearch-dom"

export const SearchStats = ({ children }) => {
  return (
    <Flex
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 27,
      }}
    >
      {children}
    </Flex>
  )
}

export const SearchStatsHits = connectStats(({ query, nbHits }) => (
  <h2 sx={{ fontWeight: "bold" }}>
    {nbHits.toLocaleString()} results{" "}
    {query !== undefined ? `for "${query}"` : ""}
  </h2>
))

const CustomHitsPerPage = ({
  items,
  currentRefinement,
  query,
  searchState,
  onSearchStateChange,
}) => (
  <Flex
    sx={{
      alignItems: "baseline",
      color: `${query ? "black" : "#585858"}`,
      variant: "search.underlineLink",
    }}
  >
    <label htmlFor="items-per-page">Items per page:</label>
    <Select
      id="items-per-page"
      sx={{
        variant: "search.underlineLink",
        width: 55,
        color: `${query ? "black" : "#585858"}`,
        "& + svg": { color: `${query ? "black" : "#585858"}` },
        "&:focus-visible": { outlineOffset: "-3px", outlineColor: "yellow" },
      }}
      value={currentRefinement || ""}
      onChange={(event) => {
        event.preventDefault()
        onSearchStateChange({
          ...searchState,
          hitsPerPage: event.currentTarget.value,
        })
      }}
    >
      {items.map((item) => (
        <option
          key={item.value}
          value={item.isRefined ? currentRefinement : item.value}
        >
          {item.label}
        </option>
      ))}
    </Select>
  </Flex>
)

export const HitsPerPage = connectHitsPerPage(CustomHitsPerPage)

export const SearchStatsHitsPerPage = ({
  query,
  searchState,
  onSearchStateChange,
}) => (
  <HitsPerPage
    query={query}
    defaultRefinement={parseInt(searchState.hitsPerPage) || 24}
    searchState={searchState}
    onSearchStateChange={onSearchStateChange}
    items={[
      { value: 12, label: "12" },
      { value: 24, label: "24" },
      { value: 36, label: "36" },
    ]}
  />
)
