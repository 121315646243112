/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { connectPagination } from "react-instantsearch-dom"

const SearchPagination = ({
  currentRefinement: page,
  nbPages,
  searchState,
  onSearchStateChange,
  smoothScrollToFilters,
}) => {
  // If there is no results (aka 0 pages) don't show the pagination, but if there is results, show the pagination even if there is only 1 page.
  if (!nbPages) return null

  const onNext = () => {
    // Only go to the next page if it's not the last page
    if (page < nbPages) {
      onSearchStateChange({
        ...searchState,
        page: page + 1,
      })
    }

    smoothScrollToFilters()
  }

  const onPrev = () => {
    // Only go to the previous page if it's not the first page
    if (page > 1) {
      onSearchStateChange({
        ...searchState,
        page: page - 1,
      })
    }

    smoothScrollToFilters()
  }

  const COLOR = {
    DISABLED: "#585858",
    NORMAL: "black",
  }

  return (
    <div sx={{ maxWidth: 160 }}>
      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <button
          sx={{
            variant: "search.underlineLink",
            fontSize: 3,
            color: COLOR.NORMAL,
            "&:disabled": { color: COLOR.DISABLED, cursor: "not-allowed" },
          }}
          onClick={onPrev}
          disabled={page === 1}
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ marginRight: 10 }}
            stroke={page === 1 ? COLOR.DISABLED : COLOR.NORMAL}
          >
            <path
              d="M6 11L1 6L6 1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Prev
        </button>
        <button
          sx={{
            variant: "search.underlineLink",
            fontSize: 3,
            color: COLOR.NORMAL,
            "&:disabled": { color: COLOR.DISABLED, cursor: "not-allowed" },
          }}
          onClick={onNext}
          disabled={page === nbPages}
        >
          Next
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ marginLeft: 10 }}
            stroke={page === nbPages ? COLOR.DISABLED : COLOR.NORMAL}
          >
            <path
              d="M1 11L6 6L1 1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Flex>
    </div>
  )
}

export default connectPagination(SearchPagination)
