import PropTypes from "prop-types"

export const richContentDownloadsProp = PropTypes.shape({
  title: PropTypes.string,
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      download: PropTypes.shape({
        fileSizeFormatted: PropTypes.string,
        fileTypeFormatted: PropTypes.string,
        sourceLocal: PropTypes.shape({
          publicUrl: PropTypes.string,
        }),
        sourceRemote: PropTypes.string,
        title: PropTypes.string,
      }),
    })
  ),
})

export const hitProp = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  objectID: PropTypes.string.isRequired,
  postType: PropTypes.string.isRequired,
  richContent: PropTypes.arrayOf(richContentDownloadsProp),
})

export const childrenElementProp = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]).isRequired
