/** @jsx jsx */
import { jsx } from "theme-ui"
import SearchIcon from "src/assets/svg/search.inline.svg"
import CloseIcon from "src/assets/svg/close.inline.svg"

export const CloseButton = (props) => (
  <button
    sx={{
      variant: "button.reset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 50,
      width: 50,
      color: "black",

      "& > svg": {
        width: "10px",
        height: "10px",
      },

      "&:focus-visible": {
        outlineColor: "yellow",
        outlineOffset: "-5px",
      },
    }}
    aria-label="Clear"
    {...props}
  >
    <CloseIcon />
  </button>
)

export const SearchButton = (props) => (
  <button
    sx={{
      variant: "button.reset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 50,
      width: 50,

      "&:focus-visible": {
        outlineColor: "yellow",
        outlineOffset: "-5px",
      },
    }}
    aria-label="Search"
    {...props}
  >
    <SearchIcon />
  </button>
)
