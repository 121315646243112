/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import PropTypes from "prop-types"
import { connectHits } from "react-instantsearch-dom"
import { hitProp } from "./SearchHitProps"

import {
  SearchItem,
  SearchItemTitle,
  SearchItemDescription,
  SearchItemHeader,
  SearchItemMain,
  SearchItemFooter,
  SearchItemDownloadList,
  SearchCategroyTaxonomies,
  SearchFormattedDate,
  SearchLink,
} from "./components"

/** Helpers **/

const getNumberOfDownloads = (content) => {
  if (!content) {
    return 0
  }
  return content
    .filter(Boolean)
    .flatMap(({ downloads = [] }) =>
      downloads.flatMap(({ download }) => download)
    ).length
}

const getPageNameFromPostType = (postType) => {
  const pageNameMap = {
    News: "News page",
    Resource: "Resource page",
    "Policy submissions": "Policy submission page",
    Article: "Article page",
    "Legal interventions": "Legal interventions page",
    Page: "Page",
  }
  return pageNameMap[postType] || "Page"
}

const SearchHits = ({ hits, query, searchOrder }) => {
  if (query && hits.length === 0) {
    return (
      <Flex sx={{ flexWrap: "wrap", maxWidth: 768 }}>
        <p
          sx={{
            fontWeight: "bold",
            fontSize: "24px",
            lineHeight: "26px",
            letterSpacing: "-0.01em",
          }}
        >
          Sorry we couldn't find that
        </p>
      </Flex>
    )
  }
  if (hits.length === 0) {
    return null
  }

  // Feature: Sort Priority
  // - Search hits can be reordered based on a post type priority list in the WP admin Options page 'Search'
  // - Enabling isSearchPostTypePriority, the results are sliced by the threshold number (searchPriorityOrderThreshold) and reordered by the post type order array (searchPostTypePriorityOrder). e.g. ['Page', 'Resource']

  let newHits = [...hits]

  const {
    searchPriorityOrderThreshold = 0,
    searchPostTypePriorityOrder = [],
    isSearchPostTypePriority = false,
  } = searchOrder || {}

  if (isSearchPostTypePriority) {
    // Reverse array to get the correct order
    const postTypeOrderArray = [...searchPostTypePriorityOrder].reverse()
    // Sort Threshold
    const sortHits = [...hits]
      .slice(0, searchPriorityOrderThreshold + 1)
      .sort((a, b) =>
        postTypeOrderArray.indexOf(a.postType) >
        postTypeOrderArray.indexOf(b.postType)
          ? -1
          : 1
      )
    const restHits = [...hits].slice(searchPriorityOrderThreshold)
    newHits = [...sortHits, ...restHits]
  }

  return newHits.map((hit, index) => {
    const numberOfDownloads = getNumberOfDownloads(hit.richContent)
    const { postType, title, link, description, date, taxonomies } = hit

    return numberOfDownloads > 0 ? (
      <SearchItem key={`${title}${index}`}>
        <SearchItemHeader>
          <p>{getPageNameFromPostType(postType)}</p>
        </SearchItemHeader>
        <SearchItemMain>
          <SearchItemTitle link={link}>{title}</SearchItemTitle>
          <SearchItemDescription>{description}</SearchItemDescription>
          <SearchFormattedDate date={date} sx={{ mb: 3 }} />
          <SearchItemDownloadList hit={hit} sx={{ mb: 6 }} />
        </SearchItemMain>
        <SearchItemFooter>
          <SearchCategroyTaxonomies taxonomies={taxonomies} />
          <SearchLink to={link}>View this page</SearchLink>
        </SearchItemFooter>
      </SearchItem>
    ) : (
      <SearchItem key={`${title}${index}`}>
        <SearchItemHeader>
          <p>{getPageNameFromPostType(postType)}</p>
        </SearchItemHeader>
        <SearchItemMain>
          <SearchItemTitle link={link}>{title}</SearchItemTitle>
          <SearchItemDescription>{description}</SearchItemDescription>
          <SearchFormattedDate date={date} sx={{ mb: 3 }} />
        </SearchItemMain>
        <SearchItemFooter>
          <SearchCategroyTaxonomies taxonomies={taxonomies} />
          <SearchLink to={link}>View this page</SearchLink>
        </SearchItemFooter>
      </SearchItem>
    )
  })
}

SearchHits.propTypes = {
  hits: PropTypes.arrayOf(hitProp).isRequired,
}

export default connectHits(SearchHits)
