/** @jsx jsx */
import { jsx } from "theme-ui"

const Arrow = ({ isOpen }) => (
  <svg
    sx={{
      transform: () => (isOpen ? "rotate(180deg)" : "rotate(0deg)"),
    }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.5L6 6.5L11 1.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Arrow
